<template>
  <div class="orderitems-header">
    <div class="container">
      <router-link to="/admin" class="breadcrumb">
        <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Admin
      </router-link>
      <h1 class="text__center padding__sm">Order Items</h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
</style>