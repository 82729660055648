<template>
  <li class="orderitem-list--item">
    <!-- <div class="flex__between">
         <span class="text__bold">11-11-2020 11:59am</span>
         <router-link to="/past-order/id/order-confirm" class="btn btn__sm btn__frost">View Order</router-link>
    </div>-->
    <div class="banner-top flex__center">
      <span class="text__white text__bold">Target $10 Gift Card</span>
    </div>
    <div class="orderitem-list--item__grid tile">
      <div class="flex__center">
        <span class="text__bold">11-11-2020 11:59am</span>
      </div>
      <div class="flex__between">
        <span class="text__bold">Order Type</span>
        <span>Standard</span>
      </div>
      <div class="flex__between">
        <span class="text__bold">Status</span>
        <span>Pending</span>
      </div>
      <div class="flex__between">
        <span class="text__bold">Receipt</span>
        <span>None</span>
      </div>
      <!-- <div class="flex__between">
            <span class="text__bold">Invoice Cost</span>
            <span>$10.00</span>
         </div>
         <div class="flex__between">
            <span class="text__bold">LO Cost</span>
            <span>$11.50</span>
      </div>-->
      <div class="flex__between">
        <span class="text__bold">Quantity</span>
        <span>12</span>
      </div>
      <div class="flex__center">
        <router-link to="/past-order/id/order-confirm" class="btn btn__sm btn__frost">View Order</router-link>
      </div>
    </div>
    <div class="banner-bottom flex__center">
      <span class="text__white">Pending</span>
      <font-awesome-icon icon="hourglass-half" size="1x" class="ml-10 text__white" />
    </div>
  </li>
</template>

<script>
export default {
  //given product ID, lookup product name & image(?)
  //if completed use "check" icon
};
</script>

<style scoped lang="scss">
.orderitem-list--item__grid {
  @include grid($cols: 1fr, $row-gap: 5px);
}
</style>