<template>
  <section class="margin__md">
    <div class="tile-heading">
      <h2>Results</h2>
      <hr />
    </div>
    <ul class="orderitems-list">
      <OrderItem v-for="num in 15" :key="num" />
    </ul>
  </section>
</template>

<script>
import OrderItem from "./OrderItem";
export default {
  components: {
    OrderItem
  }
};
</script>

<style scoped lang="scss">
.orderitems-list {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
}
@media (min-width: $md) {
  .orderitems-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .orderitems-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>