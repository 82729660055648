<template>
  <section class="orderitem-filters tile">
    <div class="flex__between">
      <h3>Filters</h3>
      <button class="btn btn__sm btn__green">Export</button>
    </div>
    <div class="orderitem-filters__grid m-tb-sm">
      <div class="grid__normal">
        <label>
          Date Start
          <input
            type="date"
            v-mask="'####-##-##'"
            v-model="form.dateStart"
            placeholder="yyyy-mm-dd"
          />
        </label>
        <label>
          Date End
          <input
            type="date"
            v-mask="'####-##-##'"
            v-model="form.dateEnd"
            placeholder="yyyy-mm-dd"
          />
        </label>
        <label>
          Order Item Status
          <select v-model="form.status">
            <option value>Any</option>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
          </select>
        </label>
        <label>
          Has Receipt
          <select v-model="form.hasReceipt">
            <option value>Any</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </label>
      </div>
      <label for="search">
        Products
        <div class="orderitems-products">
          <div class="search--bar">
            <input type="text" v-model="search" placeholder="product name" id="search" />
            <div class="btn__search">
              <font-awesome-icon icon="search" size="1x" />
            </div>
          </div>
          <div class="orderitems-products--content">
            <simplebar class="products--overflow" data-simplebar-auto-hide="false">
              <ul class="orderitems-products--list">
                <li v-for="num in 25" :key="num" :value="num">
                  <label>
                    <input type="checkbox" v-model="form.products" :value="num" />
                    Option {{ num }}
                  </label>
                </li>
              </ul>
            </simplebar>
          </div>
        </div>
      </label>
    </div>
    <div class="flex__between">
      <button class="btn btn__sm btn__red" @click="clearFilters">Clear</button>
      <button class="btn btn__sm btn__frost">Filter</button>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      form: {
        status: "",
        products: [],
        hasReceipt: "",
        dateStart: "",
        dateEnd: ""
      }
    };
  },
  methods: {
    clearFilters() {
      this.form.status = "";
      this.form.products = [];
      this.form.hasReceipt = "";
      this.form.dateStart = "";
      this.form.dateEnd = "";
      //submit filters to retreive fresh data
    },
    setDateStart() {
      if (this.$route.query.datestart) {
        this.form.dateStart = this.$route.query.datestart;
      }
    },
    setDateEnd() {
      if (this.$route.query.dateend) {
        this.form.dateEnd = this.$route.query.dateend;
      }
    },
    setStatus() {
      if (this.$route.query.status) {
        this.form.status = this.$route.query.status;
      }
    }
  },
  mounted() {
    // trigger filter submit after done
    this.setDateStart();
    this.setDateEnd();
    this.setStatus();
  }
};
</script>

<style scoped lang="scss">
.orderitem-filters__grid {
  @include grid($cols: 1fr, $row-gap: 8px, $col-gap: 25px);
  label {
    font-weight: bold;
    @include grid($cols: 1fr, $row-gap: 3px, $col-gap: 10px);
  }
}
.grid__normal {
  @include grid($cols: 1fr, $row-gap: 8px, $col-gap: 10px);
}
.orderitems-products {
  @include grid($cols: 1fr, $row-gap: 10px);
}

.orderitems-products--content {
  border: 1px solid $black;
  border-radius: 5px;
  padding: 10px;
}
.products--overflow {
  height: 100px;
}
.orderitems-products--list {
  @include grid($cols: 1fr, $row-gap: 5px, $col-gap: 10px);
  li label {
    @include grid($cols: 1fr 7fr, $col-gap: 5px, $align: center);
  }
}
@media (min-width: $xs) {
  .grid__normal {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $sm) {
  .orderitems-products--list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $md) {
  .grid__normal {
    grid-column-gap: 25px;
  }
}
@media (min-width: $lg) {
  .orderitem-filters__grid {
    grid-template-columns: 1fr 1fr;
  }
  .grid__normal {
    grid-row-gap: 20px;
  }
}
</style>