<template>
  <section class="orderitems">
    <Header />
    <div class="container">
      <Filters />
      <OrderItems />
    </div>
  </section>
</template>

<script>
import Header from "./Header";
import Filters from "./Filters";
import OrderItems from "./OrderItems/Index";
export default {
  components: {
    Header,
    Filters,
    OrderItems
  }
};
</script>

<style scoped lang="scss">
</style>